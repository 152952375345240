import { Form, FormInstance, Input, Radio } from 'antd'
import { scanSVG } from 'assets/svg/scan'
import { InputWithSuffixWrapper } from 'atom/input'
import {
  SelfServeInsideRadioGroupWrapper,
  SelfServeRadioGroupWrapper,
  SelfServeRadioInnerGroupWrapper,
} from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import SuffixButton from 'components/button/SuffixButton'
import LabelWithInfo from 'components/label/LabelWithInfo'
import MobileFullModal from 'components/modal/MobileFullModal'
import UploadImage from 'components/upload/UploadImage'
import InfoModal from 'feature/modal/projects/InfoModal'
import { requiredRule } from 'helpers/rules'
import useAddChargerForm from 'hooks/projects/useAddChargerForm'
import { SelfServePanelChargerSchema, SelfServePanelSchema } from 'models/self-serve-commissioning'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { UploadSelfServeImage } from 'services/data-provider/self-serve-commissioning'

export interface AddChargerFormProps {
  form: FormInstance<any>
  panels?: SelfServePanelSchema[]
  handleModalOpen: () => void
  initialValues?: SelfServePanelChargerSchema
  updateInstallation: (
    request: SelfServePanelChargerSchema,
    existingCharger?: SelfServePanelChargerSchema,
    callBack?: () => void,
  ) => void
}

const AddChargerForm: React.FC<AddChargerFormProps> = ({
  form,
  handleModalOpen,
  panels,
  initialValues,
  updateInstallation,
}) => {
  const {
    openChargerInfo,
    handleOpenChargerInfo,
    chargerInfoData,
    currentInitialValues,
    openScanner,
    openTextScanner,
    handleScanner,
    handleTextScanner,
    qrCodeScan,
    handleValuesChange,
    handleImageUpload,
    onFinish,
  } = useAddChargerForm({ form, handleModalOpen, initialValues, updateInstallation })

  return (
    <>
      <InfoModal
        title={'Serial Number Guidelines'}
        infoData={chargerInfoData}
        isModalOpen={openChargerInfo}
        handleModalOpen={handleOpenChargerInfo}
      />
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        layout="vertical"
        requiredMark={false}
        name="charger-form"
        onFinish={onFinish}
        initialValues={currentInitialValues}
      >
        <SelfServeRadioGroupWrapper>
          <InputWithSuffixWrapper>
            <MobileFullModal
              isModalOpen={openScanner}
              saveTitle="Close"
              destroyOnClose
              onClose={handleScanner}
              onFinish={handleScanner}
            >
              <BarcodeScannerComponent facingMode="environment" onUpdate={qrCodeScan(handleScanner, 'listingTitle')} />
            </MobileFullModal>
            <MobileFullModal
              isModalOpen={openTextScanner}
              saveTitle="Close"
              destroyOnClose
              onClose={handleTextScanner}
              onFinish={handleTextScanner}
            >
              <BarcodeScannerComponent
                facingMode="environment"
                onUpdate={qrCodeScan(handleTextScanner, 'serialNumber', true)}
              />
            </MobileFullModal>
            <Form.Item
              name="listingTitle"
              label={'Charger ID'}
              rules={[requiredRule('Charger ID')]}
              style={styleDisplay(100)}
            >
              <Input placeholder="Charger ID" suffix={<SuffixButton onClick={handleScanner}>{scanSVG}</SuffixButton>} />
            </Form.Item>
            <Form.Item
              name="serialNumber"
              label={
                <LabelWithInfo
                  title="Charger S/N"
                  subTitle={'Example: 5SW1-1-4827-00012'}
                  onClick={handleOpenChargerInfo}
                />
              }
              rules={[requiredRule('Charger S/N')]}
              style={styleDisplay(100)}
            >
              <Input
                placeholder="Charger S/N"
                suffix={<SuffixButton onClick={handleTextScanner}>{scanSVG}</SuffixButton>}
              />
            </Form.Item>
            <SelfServeRadioInnerGroupWrapper>
              <Form.Item name="panelLabel" label={'Panel'} rules={[requiredRule('Panel')]} style={styleDisplay(100)}>
                <Radio.Group>
                  {panels?.map(({ label, breakers }) => (
                    <>
                      <Radio key={label} value={label}>
                        {label}
                      </Radio>
                      {currentInitialValues?.panelLabel === label && (
                        <SelfServeInsideRadioGroupWrapper>
                          <Form.Item
                            name="breakerNumber"
                            label={'Breaker'}
                            rules={[requiredRule('Breaker')]}
                            style={styleDisplay(100)}
                            className="heading-06-regular"
                          >
                            <Radio.Group>
                              {breakers?.map(({ breakerNumber }) => (
                                <Radio key={breakerNumber} value={breakerNumber}>
                                  {breakerNumber}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </SelfServeInsideRadioGroupWrapper>
                      )}
                    </>
                  ))}
                </Radio.Group>
              </Form.Item>
            </SelfServeRadioInnerGroupWrapper>
            <Form.Item
              name="photos"
              label={'Photos of EV Charger'}
              rules={[requiredRule('Photos of EV Charger', true)]}
              style={styleDisplay(100)}
            >
              <UploadImage
                loadedImages={currentInitialValues?.photos || []}
                setLoadedImages={handleImageUpload}
                uploadFunction={UploadSelfServeImage}
                type="charger"
                maxImages={3}
              />
            </Form.Item>
          </InputWithSuffixWrapper>
        </SelfServeRadioGroupWrapper>
      </Form>
    </>
  )
}

export default AddChargerForm

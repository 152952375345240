import { Col, Spin } from 'antd'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import SessionExpiredResult from '../../components/result/SessionExpiredResult'
import { Links } from '../../components/routes/paths'
import { AlertWarning } from '../../components/warning'
import { log } from '../../logger'
import auth0Obj from '../../services/data-provider/auth0Obj'
import { clearSession, getSession } from '../../state/session-store'
import { plusSVG } from 'assets/svg/plus'
import { Box } from 'atom/box'
import PrimaryButton from 'components/button/PrimaryButton'
import InfoCard from 'components/card/InfoCard'
import CompletedForm from 'components/form/projects/CompletedForm'
import ContactSwtchForm from 'components/form/projects/ContactSwtchForm'
import SaveInfoForm from 'components/form/projects/SaveInfoForm'
import CoreHeader from 'components/header/CoreHeader'
import { AuthenticatedLayoutChildProps } from 'components/layouts/authenticated'
import MobileFullModal from 'components/modal/MobileFullModal'
import { installerPermissions } from 'helpers/roles'
import { isMobileFullView } from 'helpers/routes'
import useProjects from 'hooks/projects/useProjects'
import { useLocation } from 'react-router'
import { useAppState } from 'state'
import { theme } from 'theme'

const ProjectsBasePage: React.FC<AuthenticatedLayoutChildProps> = ({ showProfile, setShowProfile }) => {
  const { IsMobile } = useAppState()
  const session = getSession()
  const isMobile = IsMobile()
  const location = useLocation()

  const mobileFullView = isMobileFullView(location.pathname, isMobile)
  // Check if the current user has installer permissions
  const isInstaller = session?.permissions?.some((permission) => installerPermissions.includes(permission)) || false

  const signatureExpired = localStorage.getItem('signatureExpired')

  const {
    error,
    infoModalClosable,
    installations,
    selectedInstallation,
    currentStep,
    formLoading,
    openNewSite,
    infoForm,
    infoFormInitialValues,
    openContactSwtchform,
    openCompletedForm,
    progress,
    onFinishInfoModal,
    handleNewSite,
    handleInfoModal,
    handleCardClick,
    handleContactForm,
    handleOpenContactForm,
    handleOpenCompletedForm,
    submitInstallation,
    formSteps,
  } = useProjects(showProfile, setShowProfile)

  return (
    <>
      {signatureExpired === 'true' ? (
        <>
          <SessionExpiredResult />
          <Spin size="large">
            {log('Session Expired. Logging out the user and redirecting to the auth login page')}
            {setTimeout(() => {
              clearSession()
              auth0Obj.logout({})
              window.location.replace(Links.auth0login())
            }, 5000)}
          </Spin>
        </>
      ) : (
        <>
          <AlertError error={error} closable={true} />
          <AlertWarning error={error} closable={true} />
          <MobileFullModal
            isModalOpen={showProfile}
            saveTitle="Save"
            onFinish={infoForm.submit}
            closable={infoModalClosable}
            maskClosable={infoModalClosable}
            onClose={handleInfoModal}
          >
            <SaveInfoForm form={infoForm} onFinish={onFinishInfoModal} initialValues={infoFormInitialValues} />
          </MobileFullModal>
          <MobileFullModal
            isModalOpen={openCompletedForm}
            saveTitle="Done"
            onFinish={submitInstallation('complete', handleOpenCompletedForm)}
            closable={false}
            maskClosable={false}
            onClose={handleOpenCompletedForm}
          >
            <CompletedForm />
          </MobileFullModal>
          <MobileFullModal
            isModalOpen={openContactSwtchform}
            saveTitle="Contact"
            onFinish={handleContactForm}
            closable={false}
            maskClosable={false}
            onClose={handleOpenContactForm}
          >
            <ContactSwtchForm />
          </MobileFullModal>
          <MobileFullModal
            loading={formLoading}
            hideFooter={selectedInstallation?.status === 'complete'}
            isModalOpen={openNewSite}
            title={`Commission a Site · ${progress}%`}
            progress={progress}
            saveTitle={formSteps[currentStep]?.saveTitle}
            onFinish={formSteps[currentStep]?.onFinish}
            closable
            onClose={handleNewSite}
            cancelText={formSteps[currentStep]?.cancelText}
            onCancel={formSteps[currentStep]?.onCancel}
            disableSubmit={formSteps[currentStep]?.disableSubmit}
          >
            {formSteps[currentStep].components}
          </MobileFullModal>
          <Box
            backgroundColor={mobileFullView ? theme.colors.white : 'transparent'}
            noMargin={mobileFullView}
            className="mobile-full-cover"
          >
            <CoreHeader
              title={
                <Col style={{ color: '#000' }}>
                  <p>
                    <b>{`Welcome to Self-Serve Commissioning!`}</b>
                  </p>
                  <p>{`The SWTCH Self-Serve Commissioning tool gives you the autonomy to manage commissioning on your own—no coordination needed! This enables you to quickly ensure that newly installed EV charging infrastructure is online, accelerating the path to SWTCH charger activation.`}</p>
                  <p>
                    To get started, simply select <b>{`Commission a New Site.`}</b>
                  </p>
                  <p>{`Note: Once you’ve commissioned a site, it will appear below, giving you easy access to all related information whenever you need it.`}</p>
                </Col>
              }
              rightElement={
                isInstaller ? (
                  <PrimaryButton onClick={handleNewSite} size="large" title={`Commission a new site`} icon={plusSVG} />
                ) : undefined
              }
            />
            <Col>
              {installations.map((item, index) => (
                <InfoCard
                  key={`installation-${index}`}
                  title={item?.site?.address || ''}
                  status={item.status}
                  lastUpdatedDate={item?.updatedAt}
                  onClick={() => handleCardClick(item)}
                />
              ))}
            </Col>
          </Box>
        </>
      )}
    </>
  )
}

export const ProjectsPage = withAuthenticatedLayout(ProjectsBasePage)

import { Form, FormInstance, Input, Radio } from 'antd'
import { SelfServeRadioGroupWrapper } from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import LabelWithButton from 'components/label/LabelWithButton'
import LabelWithInfo from 'components/label/LabelWithInfo'
import LabelWithSubtitle from 'components/label/LabelWithSubtitle'
import UploadImage from 'components/upload/UploadImage'
import InfoModal from 'feature/modal/projects/InfoModal'
import PhotoGuideLineModal from 'feature/modal/projects/PhotoGuideLineModal'
import { noSpecialCharacters, onlyNumbers, requiredRule } from 'helpers/rules'
import useAddPanelForm from 'hooks/projects/useAddPanelForm'
import { SelfServePanelSchema } from 'models/self-serve-commissioning'
import { UploadSelfServeImage } from 'services/data-provider/self-serve-commissioning'

export interface AddPanelFormProps {
  form: FormInstance<any>
  handleModalOpen: () => void
  initialValues?: SelfServePanelSchema
  updateInstallation: (
    request: SelfServePanelSchema,
    existingPanel?: SelfServePanelSchema,
    callBack?: () => void,
  ) => void
}

const AddPanelForm: React.FC<AddPanelFormProps> = ({ form, handleModalOpen, initialValues, updateInstallation }) => {
  const {
    currentInitialValues,
    typeOfPanel,
    infoData,
    openPanelTypeHelp,
    photoGuidelines,
    openGuideLines,
    handleGuideLines,
    handlePanelTypeHelp,
    handleValuesChange,
    handleImageUpload,
    onFinish,
  } = useAddPanelForm({
    form,
    handleModalOpen,
    initialValues,
    updateInstallation,
  })

  return (
    <>
      <PhotoGuideLineModal infoData={photoGuidelines} isModalOpen={openGuideLines} handleModalOpen={handleGuideLines} />
      <InfoModal
        title={'Panel Type'}
        infoData={infoData}
        isModalOpen={openPanelTypeHelp}
        handleModalOpen={handlePanelTypeHelp}
      />
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        layout="vertical"
        requiredMark={false}
        name="panel-form"
        onFinish={onFinish}
        initialValues={currentInitialValues}
      >
        <SelfServeRadioGroupWrapper>
          <Form.Item
            name="label"
            label={<LabelWithSubtitle title="Panel Label" subTitle="Example: EV CHARGER 1 PANEL" />}
            rules={[requiredRule('Panel Label')]}
            style={styleDisplay(100)}
          >
            <Input placeholder="Panel Label" />
          </Form.Item>
          <Form.Item
            name="type"
            label={<LabelWithInfo title="Panel Type" onClick={handlePanelTypeHelp} />}
            rules={[requiredRule('Panel Type')]}
            style={styleDisplay(100)}
          >
            <Radio.Group>
              {typeOfPanel.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="ratedCapacity"
            label={'Rated Panel Size (A)'}
            rules={[requiredRule('Rated Panel Size (A)'), noSpecialCharacters, onlyNumbers]}
            style={styleDisplay(100)}
          >
            <Input type="number" placeholder="Rated Panel Size (A)" />
          </Form.Item>
          <Form.Item
            name="loc"
            label={<LabelWithSubtitle title="Panel Location" subTitle="Example: Electrical room on P2" />}
            rules={[requiredRule('Panel Location')]}
            style={styleDisplay(100)}
          >
            <Input placeholder="Panel Location" />
          </Form.Item>
          <Form.Item
            name="phaseType"
            label={'Phase Type'}
            rules={[requiredRule('Phase Type')]}
            style={styleDisplay(100)}
          >
            <Radio.Group>
              {[
                { value: 'single', label: 'Single Phase (120/240V)' },
                { value: 'three', label: 'Three Phase (120/208V)' },
              ].map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="photos"
            label={<LabelWithButton title="Photos of Panel" onClick={handleGuideLines} buttonTitle="See guidelines" />}
            rules={[requiredRule('Photos of Panel', true)]}
            style={styleDisplay(100)}
          >
            <UploadImage
              loadedImages={currentInitialValues?.photos || []}
              setLoadedImages={handleImageUpload}
              uploadFunction={UploadSelfServeImage}
              type="panel"
              maxImages={3}
            />
          </Form.Item>
        </SelfServeRadioGroupWrapper>
      </Form>
    </>
  )
}

export default AddPanelForm

import { Form, FormInstance, FormProps, Input, Radio } from 'antd'
import { SelfServeRadioGroupWrapper } from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import GoogleAutoComplete from 'components/autoComplete/GoogleAutoComplete'
import WarningCard from 'components/card/WarningCard'
import LabelWithButton from 'components/label/LabelWithButton'
import LabelWithInfo from 'components/label/LabelWithInfo'
import LabelWithSubtitle from 'components/label/LabelWithSubtitle'
import UploadImage from 'components/upload/UploadImage'
import PhotoGuideLineModal from 'feature/modal/projects/PhotoGuideLineModal'
import InfoModal from 'feature/modal/projects/InfoModal'
import { noSpecialCharacters, requiredRule } from 'helpers/rules'
import useNetworkForm from 'hooks/projects/useNetworkForm'
import {
  SelfServeNetworkSchema,
  SelfServeNetworkSchemaForm,
  SelfServeSiteSchema,
} from 'models/self-serve-commissioning'
import { UploadSelfServeImage } from 'services/data-provider/self-serve-commissioning'

export interface NetworkFormProps extends FormProps {
  form: FormInstance<any>
  initialValues?: SelfServeNetworkSchema
  initialSiteInfo?: SelfServeSiteSchema
  createOrGetSite: (address: string) => Promise<boolean>
  goToNextStep: () => void
  updateInstallation: (request: SelfServeNetworkSchema[], callBack?: () => void) => Promise<void>
  setUserInputNetworkFields: (values: SelfServeNetworkSchemaForm) => void
}

const NetworkForm: React.FC<NetworkFormProps> = ({
  form,
  initialValues,
  initialSiteInfo,
  createOrGetSite,
  goToNextStep,
  updateInstallation,
  setUserInputNetworkFields,
}) => {
  const {
    siteAlreadyExists,
    typeOfNetworks,
    openTypeOfNetworkHelp,
    openGuideLines,
    currentInitialValues,
    photoGuidelines,
    infoData,
    handleTypeOfNetworkHelp,
    handleGuideLines,
    handleSelect,
    handleImageUpload,
    handleValuesChange,
    onFinish,
  } = useNetworkForm({
    form,
    initialValues,
    initialSiteInfo,
    createOrGetSite,
    updateInstallation,
    setUserInputNetworkFields,
    goToNextStep,
  })

  return (
    <>
      <PhotoGuideLineModal infoData={photoGuidelines} isModalOpen={openGuideLines} handleModalOpen={handleGuideLines} />
      <InfoModal
        title={'Type of Network'}
        infoData={infoData}
        isModalOpen={openTypeOfNetworkHelp}
        handleModalOpen={handleTypeOfNetworkHelp}
      />
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        layout="vertical"
        requiredMark={false}
        name="network-form"
        onFinish={onFinish}
        initialValues={currentInitialValues}
      >
        <p className="heading-03-bold">{'Network'}</p>
        <Form.Item
          name="site"
          label="Installation Address"
          rules={[
            requiredRule('Installation Address'),
            {
              validator: async (_: unknown, value: unknown) => {
                if (siteAlreadyExists) {
                  throw new Error(
                    'This tool currently does not support commissioning at this address. Contact SWTCH to proceed with this installation.',
                  )
                }
              },
            },
          ]}
          style={styleDisplay(100)}
        >
          <GoogleAutoComplete onSelect={handleSelect} placeholder="Search for a location" allowClear />
        </Form.Item>
        <SelfServeRadioGroupWrapper>
          <Form.Item
            name="type"
            label={<LabelWithInfo title="Type of Network" onClick={handleTypeOfNetworkHelp} />}
            rules={[requiredRule('Type of Network')]}
            style={styleDisplay(100)}
          >
            <Radio.Group>
              {typeOfNetworks.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </SelfServeRadioGroupWrapper>

        {!currentInitialValues?.type ? (
          <></>
        ) : currentInitialValues?.type !== 'swtch' ? (
          <WarningCard description="This tool currently supports commissioning with the SWTCH Communication Hardware. Contact SWTCH to proceed with all other types of networks." />
        ) : (
          <>
            <Form.Item
              name="pin"
              label={<LabelWithSubtitle title="Network PIN" subTitle="Example: N2116" />}
              rules={[requiredRule('Network PIN'), noSpecialCharacters()]}
              style={styleDisplay(100)}
            >
              <Input placeholder="Network PIN" />
            </Form.Item>
            <Form.Item
              name="router"
              label={
                <LabelWithButton
                  title="Photos of Communication Hardware"
                  onClick={handleGuideLines}
                  buttonTitle="See guidelines"
                />
              }
              rules={[requiredRule('Communication Hardware Photo', true)]}
              style={styleDisplay(100)}
            >
              <UploadImage
                loadedImages={currentInitialValues?.router || []}
                setLoadedImages={handleImageUpload('router')}
                uploadFunction={UploadSelfServeImage}
                type="router"
                maxImages={5}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  )
}

export default NetworkForm

import { Col, Row } from 'antd'

const InstallationForm: React.FC = () => {
  return (
    <Col>
      <p className="heading-03-bold">{'Getting Started'}</p>
      <p className="heading-06-regular">
        {'To commission this site, this application will collect installation information from you.'}
      </p>
      <p className="heading-05-bold" style={{ margin: '16px 0px' }}>
        {'What you will need'}
      </p>
      {[
        'Network information',
        'Panel and breaker information',
        'Photos of the panel',
        'Charger ID',
        'Charger serial number',
        'Photos of the installed charger',
      ].map((option) => (
        <Row align="middle">
          <div style={{ margin: '0px 8px', width: 5, height: 5, borderRadius: 5, backgroundColor: 'black' }} />
          <p className="heading-06-regular" style={{ marginBottom: 0 }}>
            {option}
          </p>
        </Row>
      ))}
      <p className="heading-06-regular" style={{ marginTop: 8 }}>
        {'Get started by ensuring that the EV chargers and communication hardware are installed and powered on. '}
      </p>
      <p className="heading-06-regular" style={{ marginTop: 8 }}>
        {'Check for green/blue flashing lights on devices like these examples:'}
      </p>
      {[
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/IMG_8369.png',
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/WiFi+Access+Point+is+ON+example.png',
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/LTE+is+ON+example.png',
      ].map((image, index) => (
        <img style={{ width: '100%', marginBottom: 32 }} alt={`Installation instruction ${index}`} src={image} />
      ))}
    </Col>
  )
}

export default InstallationForm

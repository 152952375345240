import { Col, FormProps } from 'antd'
import { plusSVG } from 'assets/svg/plus'
import SecondaryButton from 'components/button/SecondaryButton'
import ErrorCard from 'components/card/ErrorCard'
import SelfServeInfoCard from 'components/card/SelfServeInfoCard'
import WarningCard from 'components/card/WarningCard'
import AddChargerModal from 'feature/modal/projects/AddChargerModal'
import useChargerListForm from 'hooks/projects/useChargerListForm'
import {
  SelfServeChargerConnectivitySchema,
  SelfServePanelChargerSchema,
  SelfServePanelSchema,
} from 'models/self-serve-commissioning'

export interface ChargersListFormProps extends FormProps {
  initialValues?: SelfServePanelChargerSchema[]
  panels?: SelfServePanelSchema[]
  connectivity: SelfServeChargerConnectivitySchema[]
  getChargerConnectivity: (serialNumbers: string[]) => Promise<void>
  updateInstallation: (request: SelfServePanelChargerSchema[], callBack?: () => void) => Promise<void>
}

const ChargersListForm: React.FC<ChargersListFormProps> = ({
  initialValues,
  panels,
  connectivity,
  getChargerConnectivity,
  updateInstallation,
}) => {
  const {
    openAddCharger,
    errorMessages,
    warningMessages,
    convertStatus,
    selectedCharger,
    connectivityColors,
    handleAddCharger,
    updateCharger,
    panelModifyOptions,
  } = useChargerListForm({ initialValues, updateInstallation })

  return (
    <>
      <AddChargerModal
        panels={panels}
        updateInstallation={updateCharger}
        isModalOpen={openAddCharger}
        handleModalOpen={handleAddCharger(undefined)}
        initialValues={selectedCharger}
      />
      <Col>
        <p className="heading-03-bold">{'Chargers'}</p>
        <p className="heading-06-regular">
          {
            'Enter details for all installed chargers and select their corresponding breaker slot pairs. Once done, click “Check Connectivity” to get the connectivity strength.'
          }
        </p>
        <p className="heading-06-regular">
          Connectivity will be shown as <span style={{ color: connectivityColors['strong'] }}>Strong</span>,{' '}
          <span style={{ color: connectivityColors['acceptable'] }}>Acceptable</span>, or{' '}
          <span style={{ color: connectivityColors['weak'] }}>Weak</span>.
        </p>
        <p className="heading-06-regular">{`Chargers must have Strong or Acceptable connectivity in order to complete commissioning.`}</p>
        <SecondaryButton onClick={handleAddCharger(undefined)} size="large" title={`Add a charger`} icon={plusSVG} />
        {initialValues?.map((charger) => {
          const currentConnectivity = connectivity.find(({ serialNumber }) => serialNumber === charger.serialNumber)
          const errorConnection = currentConnectivity ? errorMessages[convertStatus(currentConnectivity)] : undefined
          const warningConnection = currentConnectivity
            ? warningMessages[convertStatus(currentConnectivity)]
            : undefined
          return (
            <>
              <SelfServeInfoCard
                image={charger?.photos?.[0]}
                title={charger?.connectors?.[0]?.listingTitle}
                subtitles={[
                  `Charger S/N: ${charger?.serialNumber}`,
                  `${charger?.panelLabel} · ${charger?.breakerNumber}`,
                ]}
                options={panelModifyOptions(charger)}
                body={{
                  title: (
                    <span>
                      {`Connectivity: `}
                      <span
                        style={{
                          color:
                            connectivityColors[currentConnectivity ? convertStatus(currentConnectivity) : 'strong'],
                          textTransform: 'capitalize',
                        }}
                        className="paragraph-01-bold"
                      >
                        {currentConnectivity ? convertStatus(currentConnectivity) : '-'}
                      </span>
                    </span>
                  ),
                  buttonText: 'Refresh',
                  onClick: () => getChargerConnectivity([charger?.serialNumber || '']),
                }}
              />
              <div style={{ marginTop: 20 }}>
                {errorConnection ? (
                  <ErrorCard description={errorConnection} />
                ) : warningConnection ? (
                  <WarningCard description={warningConnection} />
                ) : null}
              </div>
            </>
          )
        })}
      </Col>
    </>
  )
}

export default ChargersListForm

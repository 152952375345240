import { Button, Col } from 'antd'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { useAppState } from 'state'

interface InfoModalProps {
  isModalOpen: boolean
  title: string
  handleModalOpen: () => void
  infoData: {
    header?: string
    body?: string
    image?: string
  }[]
}

const InfoModal: React.FC<InfoModalProps> = ({ title, isModalOpen, handleModalOpen, infoData }) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()

  const buttonStyle = {
    width: isMobile ? '100%' : '49%',
  }

  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="OK"
      onClose={handleModalOpen}
      onFinish={handleModalOpen}
      title={title}
      footer={
        <Button key="submit" onClick={handleModalOpen} style={buttonStyle}>
          {'OK'}
        </Button>
      }
    >
      <Col>
        {infoData.map((info, index) => (
          <div style={{ marginBottom: 32 }} key={`info-data-${index}`}>
            {info.header && <p className="heading-06-regular">{info.header}</p>}
            {info.body && <p className="heading-06-light">{info.body}</p>}
            {info.image && <img alt={`${title}-${index}`} style={{ width: '100%' }} src={info.image} />}
          </div>
        ))}
      </Col>
    </MobileBottomModal>
  )
}

export default InfoModal

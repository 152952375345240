import { Card, Col } from 'antd'
import { ChevronRight } from 'assets/svg/chevron'
import CoreBadge from 'components/badge/Badge'
import { installerStatus } from 'helpers/installerStatus'
import { isMobileFullView } from 'helpers/routes'
import { useLocation } from 'react-router'
import { useAppState } from 'state'
import moment from 'moment'
export interface InfoCardProps {
  title: string
  status: keyof typeof installerStatus
  lastUpdatedDate?: string
  onClick: () => void
}

const InfoCard: React.FC<InfoCardProps> = ({ title, status, lastUpdatedDate, onClick }) => {
  const { IsMobile } = useAppState()

  const isMobile = IsMobile()
  const location = useLocation()

  const mobileFullView = isMobileFullView(location.pathname, isMobile)

  return (
    <Card
      hoverable
      onClick={onClick}
      style={{
        borderRadius: '8px',
        marginTop: 20,
        boxShadow: '0px 4px 17px 0px #00000012',
      }}
      bodyStyle={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <div
        className={`flex space-between ${mobileFullView ? 'column align-start' : 'row align-center'}`}
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        <Col className="flex column" style={{ gap: 10 }}>
          <span className="heading-05-regular" style={{ marginBottom: mobileFullView ? 10 : 0 }}>
            {title}
          </span>
          <span className="heading-06-regular" style={{ marginBottom: mobileFullView ? 10 : 0 }}>
            {`Last Updated Date: ${moment(lastUpdatedDate).format('MM/DD/YYYY')}`}
          </span>
        </Col>
        <CoreBadge
          text={<div className="custom-badge-text paragraph-01-regular">{installerStatus[status].text}</div>}
          backgroundColor={installerStatus[status].backgroundColor}
          color={installerStatus[status].color}
        />
      </div>
      <ChevronRight />
    </Card>
  )
}

export default InfoCard

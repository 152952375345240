import {
  mockCreateSelfServeInstallation,
  mockGetSelfServeChargerConnectivity,
  mockSelfServeImageResponse,
  mockSelfServeInstallationDetail,
  mockSelfServeInstallations,
  mockSelfServeUserData,
  mockUpdateSelfServeInstallation,
} from 'mock/self-serve-commissioning'
import {
  NonNullableSelfServeUserRef,
  SelfServeChargerConnectivityRef,
  SelfServeImageRef,
  SelfServeInstallationDetailRef,
  SelfServeInstallationListRef,
  SelfServeInstallationRef,
  SelfServeIsSiteExistsRef,
  SelfServeStatusType,
  SelfServeUpdateInstallationSchema,
  SelfServeUserFormData,
  SelfServeUserRef,
} from 'models/self-serve-commissioning'
import configProvider from '../../config'
import { log } from '../../logger'
import { apiCihClient } from './client'

const baseURL = '/commissioning/api/v1'

export async function GetUserInfo(): Promise<SelfServeUserRef> {
  const url = `${baseURL}/user`
  log('fetching user information', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelfServeUserData)
  }

  return apiCihClient(url, {})
}

export async function CreateOrUpdateUserInfo(newUserInfo: SelfServeUserFormData): Promise<NonNullableSelfServeUserRef> {
  const url = `${baseURL}/user/upsert-user-info`
  log('creating/updating user information', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelfServeUserData)
  }

  const { name, ...rest } = newUserInfo
  const splitName = name.split(' ')

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify({
      ...rest,
      firstName: splitName.slice(0, splitName.length - 1).join(' '),
      lastName: splitName[splitName.length - 1],
    }),
  })
}

export async function UploadSelfServeImage(image: FormData): Promise<SelfServeImageRef> {
  const url = `${baseURL}/upload/image`
  log('uploading self serve commissioning image', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelfServeImageResponse)
  }

  return apiCihClient(url, {
    method: 'POST',
    body: image,
    headers: {},
  })
}

export async function GetInstallations(): Promise<SelfServeInstallationListRef> {
  const url = `${baseURL}/installations/list-installation-requests`
  log('fetching installations', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelfServeInstallations)
  }

  return apiCihClient(url, {})
}

export async function GetInstallationDetail(id: string): Promise<SelfServeInstallationDetailRef> {
  const url = `${baseURL}/installations/get-installation-request/${id}`
  log('fetching installation detail', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelfServeInstallationDetail)
  }

  return apiCihClient(url, {})
}

export async function IsSiteExists(address: string): Promise<SelfServeIsSiteExistsRef> {
  const url = `${baseURL}/installations/is-site-exists?address=${address}`
  log('checking if site exists', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve({
      status: 'success',
      data: {
        exists: true,
      },
    })
  }

  return apiCihClient(url, {})
}

export async function CreateInstallation(): Promise<SelfServeInstallationRef> {
  const url = `${baseURL}/installations/create-installation-request`
  log('creating new installation', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockCreateSelfServeInstallation)
  }

  return apiCihClient(url, {
    method: 'POST',
  })
}

export async function UpdateInstallation(
  installationDetail: SelfServeUpdateInstallationSchema,
): Promise<SelfServeInstallationDetailRef> {
  const url = `${baseURL}/installations/update-installation-request`
  log('updating new installation', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUpdateSelfServeInstallation)
  }

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify({
      ...installationDetail,
    }),
  })
}

export async function CreateOrGetSite(
  installation_request_id: string,
  address: string,
): Promise<SelfServeInstallationDetailRef> {
  const url = `${baseURL}/installations/get-site`
  log('creating or getting site', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUpdateSelfServeInstallation)
  }

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify({
      installation_request_id,
      address,
    }),
  })
}

export async function GetChargerConnectivity(serialNumbers: string[]): Promise<SelfServeChargerConnectivityRef> {
  const url = `${baseURL}/chargers/get-charger-connectivity`
  log('getting charger connectivity', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockGetSelfServeChargerConnectivity)
  }

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify({
      serialNumbers,
    }),
  })
}

export async function SubmitInstallation(
  id: string,
  status: SelfServeStatusType,
): Promise<SelfServeInstallationDetailRef> {
  const url = `${baseURL}/installations/submit-installation-request`
  log('submitting installation', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve({
      ...mockUpdateSelfServeInstallation,
      data: {
        ...mockUpdateSelfServeInstallation.data,
        status,
      },
    })
  }

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify({
      id,
      status,
    }),
  })
}
